<template>
</template>

<script>

export default {
  name: "preViewFile",
  data() {
    return {}
    },
  props: {
    hasWater:{type: Boolean,default:false },
    fileId: { type: String },
  },
  watch: {
    fileId: {
      handler(val) {
        if(val!=null&&val!=""&&val!=undefined){
          this.preViewFile();
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods:{

    preViewFile(row){
      return new Promise((resolve, reject) => {
        let fileId = this.fileId;
        let hasWater = this.hasWater;

        let form = document.getElementById('filePreviewForm');

        if (!form) {
          form = document.createElement("form");
          form.setAttribute('id', 'filePreviewForm')
          form.setAttribute('method','post')
          form.setAttribute('action', '/psdmsfiles/filePreview/previewOfficePdf');
          form.setAttribute('target', fileId)
    
          let inputEl = document.createElement('input');
          inputEl.setAttribute("type", "hidden");
          inputEl.setAttribute("id", "fileId");
          inputEl.setAttribute("name", "fileId");
          inputEl.value = fileId;
    
          let inputEl2 = document.createElement('input');
          inputEl2.setAttribute("type", "hidden");
          inputEl2.setAttribute("name", "hasWater");
          inputEl2.value = hasWater;
          
          form.appendChild(inputEl);
          form.appendChild(inputEl2);
          document.body.appendChild(form);
        } 
        
        if (fileId && fileId !== '') {
          document.getElementById('fileId').value = fileId;
          form.setAttribute('target', fileId);
          form.submit();
          // '/psdmsfiles/filePreview/preview?fileId=?'
          window.open('', fileId);
          this.$emit("resetPreFileView")
        }
        resolve()
      });

    },
  }
}
</script>

<style scoped>

</style>
