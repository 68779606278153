<template>
  <page-view title="电子合同" left-arrow :nav-border="false" custom-class="page__dzhtgl">
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{color: '#333'}"/>
    </template>
    
    <van-search v-model="form.yhbh" placeholder="请输入用户编号" @search="fetchData"/>
    <loading-layout ref="loadingLayout"  :error-text="lvErrorText" empty-text="未查询到合同信息" @reaty="fetchData">

      <van-cell-group style="marin: 15px" class="dzht-item" v-for="(item, idx) in list" :key="idx">
        <van-cell title="合同状态">
          <van-tag :type="item.dzhtzt | dzhtztClazzFilter" size="small" plain>{{item.dzhtzt | dzhtztFilter}}</van-tag>
        </van-cell>
        <van-cell title="合同编号" :value="item.htbh"/>
        <van-cell title="用户编号" :value="item.yhbh"/>
        <van-cell title="用户名称" :value="item.ydfdwmc"/>
        <van-cell title="签约日期" :value="item.ydfqzrq"/>
        <van-cell title="联系手机" :value="item.ydfdh"/>
        <van-cell>
          <div class="text-right">
            <van-button v-if="item.dzhtzt == '1'" type="primary" size="small" class="mr-10" @click="handleSignature(item)">签订合同</van-button>
            <van-button v-if="item.dzhtzt == '3'" type="primary" size="small" class="mr-10" @click="handleSignature(item)">续签合同</van-button>
            <van-button type="info" size="small" @click="handlePreview(item)">预览</van-button>
          </div>
        </van-cell>
      </van-cell-group>
    </loading-layout>

    <pre-view-file :fileId="preViewFileEntity.fileId"  style="display: none" @resetPreFileView="resetPreFileView"></pre-view-file>
  </page-view>
</template>


<script>
import { dzhtService_getDzhtxx } from '@/api/psdmsykgl/service/DzhtServiceAPI'

import Loading from '@/components/LoadingLayout/loading.vue';
import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
import YhbhSelect from '@/components/YhbhSelect/index.vue';
import PreViewFile from '@/components/PreViewFile/index.vue'

import { Search } from 'vant';

export default {
  name: 'dzhtgl',
  components: {
    [Search.name]: Search,
    Loading,
    YhbhEmpty,
    YhbhSelect,
    PreViewFile
  },
  data() {
    return {
      lvErrorText: '电子合同查询失败',
      form: {
        yhbh: ''
      },
      list: [{},{}],
      preViewFileEntity: {
        fileId: ''
      },
    }
  },
  filters: {
    dzhtztClazzFilter(val) {
      val = val+"";
      let str = "default";
      switch (val) {
        case "2":
        case "4":
          str = "success"
          break;
        case "1":
        case "3":
          str = "warning";
          break;
        default:
          break;
      }
      return str;
    },
    dzhtztFilter(val) {
      val = val+"";
      let str = '';
      switch (val) {
        case "0":
          str = '未生成';
          break;
        case "1":
          str = '已生成待签订';
          break;
        case "2":
          str = '已签定';
          break;
        case "4":
          str = '已续签';
          break;
        case "3":
          str = '续签合同待签订';
          break;
        default:
          break;
      }
      return str;
    }
  },

  methods: {
    setYhbh(yhbh) {
      if (this.form.yhbh && this.form.yhbh != '') {
        return;
      }
      this.form.yhbh = yhbh;
    },
    yhbhChangeHandle(yhxx) {
      this.form.yhbh = yhxx.yhbh;
      this.fetchData();
    },

    fetchData(isRefresh = false) {

      let lv =  this.$refs['loadingLayout'];
      if (!this.form.yhbh || this.form.yhbh == '') {
        return this.$toast('请输入用户编号！');
      } 
      lv.setStatus('loading');
      dzhtService_getDzhtxx(this.form.yhbh).then(res => {
        if (res.status && res.status == 400) {
          throw new Error(res.message);
        }

        if (res.content && res.content.status == 400) {
          throw new Error(res.content.message);
        }

        this.list = res.content.data;
        lv.setStatus(this.list.length ? 'success': 'empty');
      }).catch(err => {
        this.lvErrorText = '合同查询失败：'+err.message;
        lv.setStatus('error');
      });

    },
    handleSignature(htxx) {
      const {qdrq, ywbh, jgbm, yhbh, htbh, dzhtzt} = htxx;
      let query = {
        qdrq, 
        ywbh, 
        jgbm, 
        yhbh, 
        htbh,
        dzhtzt
      };
      this.$router.push({name: 'signature', query: query});
    },

    handlePreview(htxx) {
      let fileId = htxx.pdfurl;
      let hasWater = false;
      this.preViewFileEntity = {
        fileId,
        hasWater
      }
      
    },
    resetPreFileView() {
      this.preViewFileEntity={fileId:""}
    }
  },

  mounted() {
    this.$store.dispatch('getYhbhList').then(list => {
      if (list.length) {
        this.setYhbh(list[0].yhbh);
        this.fetchData(true);
      }
    });
  },

  beforeRouteEnter (to, from, next) {
    // console.log(to, from, next);
    let yhbh = '';
    if (from.name == 'signature') {
      yhbh = from.query.yhbh;
    }
    next((vm) => {
      vm.setYhbh(yhbh);
    });
  }
}
</script>


<style lang="scss" scoped>
  .page__dzhtgl /deep/ {
    .van-nav-bar__right {
      padding: 0;
    }

    .dzht-item {
      margin: 10px;
      border-radius: 6px;
      overflow: hidden;

      .van-cell {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      
      .van-tag {
        line-height: 20px;
      }
      .van-button {
        padding: 0 16px;
        border-radius: 4px;
      }
    }
  }
</style>