// WaGen___HASHKEY__bc156c57_2022-10-24 17:28:50 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
生成电子合同PDF文件
*/
export function dzhtService_createDzhtPdf(qdrq,ywbh,jgbm,yhbh,flag,fileid, meta) {
	return fetch({
		url : 'psdmsykgl/service/DzhtService/createDzhtPdf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				flag : flag, //String
				fileid : fileid //String
			}
		}
	})
}

/* ---
上传用户电子签名图片文件
*/
export function dzhtService_uploadYhqmImg(yhqmFile,fileName, meta) {
	return fetch({
		url : 'psdmsykgl/service/DzhtService/uploadYhqmImg',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhqmFile : yhqmFile, //MultipartFile
				fileName : fileName //String
			}
		}
	})
}

/* ---
获取电子合同信息
*/
export function dzhtService_getDzhtxx(yhbh, meta) {
	return fetch({
		url : 'psdmsykgl/service/DzhtService/getDzhtxx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh //String
			}
		}
	})
}

